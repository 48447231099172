export const TAX_TYPE = {
  VAT_ABLE: {
    code: "VAT_ABLE",
    name: "НӨАТ тооцох бүтээгдэхүүн, үйлчилгээ",
  },
  VAT_FREE: {
    code: "VAT_FREE",
    name: "НӨАТ-аас чөлөөлөгдөх бүтээгдэхүүн, үйлчилгээ",
  },
  VAT_ZERO: {
    code: "VAT_ZERO",
    name: "НӨАТ-н 0 хувь тооцох бүтээгдэхүүн, үйлчилгээ",
  },
  NO_VAT: {
    code: "NO_VAT",
    name: "Монгол улсын хилийн гадна борлуулсан бүтээгдэхүүн үйлчилгээ",
  },
};
