import { ActionIcon, Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { ProductList } from "../../components/product/product-list";
import { useScreenDetector } from "../../components/screen-detector";
import { IAuth } from "../../interfaces/IAuth";

export const Products = () => {
  const breadcrumbs = useBreadcrumb();
  const ERP_GDS = usePermission("ERP_GDS");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [onChangeTab, setOnChangeTab] = useState<string>("1");
  const { isMobile } = useScreenDetector();

  const onClickAdd = (e: string) => {
    switch (e) {
      case "1":
        return "/product/new";
      case "2":
        return "/package-goods/new";
      default:
        return "/";
    }
  };

  return (
    <PageLayout
      title="Бараа удирдлага"
      subTitle="Барааны жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[
        user?.currentBusiness?.type === "SUPPLIER" ? (
          <Link to={onClickAdd(onChangeTab)} key={0} hidden={!ERP_GDS.isCreate}>
            {isMobile ? (
              <>
                <ActionIcon variant="filled" color="primary">
                  <IconPlus />
                </ActionIcon>
              </>
            ) : (
              <Button size="sm" key={0}>
                {onChangeTab === "1" && "Бараа нэмэх"}
                {onChangeTab === "2" && "Багц нэмэх"}
              </Button>
            )}
          </Link>
        ) : null,
      ]}>
      <ProductList />
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Бараа удирдлага",
  },
];
